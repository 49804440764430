import { Box, DialogContent, FormHelperText, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import ActionModal from "components/modals/action";
import { addShareSerie } from "services";
import { Company, Shareholder } from "const/types";
import { ShareSerieInputValues, ShareSeriePostValues } from "const/types/services";
import { BytesLike, utils } from "ethers";
import { useEffect, useState } from "react";
import { v4 } from 'uuid';
import { GetAddressArgs, useDeployNewSerie, useGetAddress } from "contracts/tools";

type AddCompanyModalProps = {
  company: Company;
  shareholders: Shareholder[]
  onSuccess: () => void;
  onError: () => void;
  onClose: () => void;
}

const emptyInitialValues: ShareSerieInputValues = {
  sharesSerieName: "",
  sharesQuantity: 0,
  shareholder: {
    shareholderId: "",
    active: "",
    chainAddress: "",
    createdAt: "",
    firstName: "",
    lastName: "",
    pesel: 0,
  }
}

const AddShareSerieModal: React.FC<AddCompanyModalProps> = ({ shareholders, company, onSuccess, onError, onClose }) => {
  const { t } = useTranslation('companySharesModals');
  const { deployState, deployTx } = useDeployNewSerie(company.companySeriesAddress);
  const [getAddressArgs, setGetAddressArgs] = useState<GetAddressArgs>();
  const newSerieAddress = useGetAddress(company.companySeriesAddress, getAddressArgs);
  const [isLoading, setIsLoading] = useState(false);
  const [readyForSubmit, setReadyForSubmit] = useState(false);

  const ShareSeriePostValuesSchema = Yup.object().shape({
    sharesSerieName: Yup.string()
      .matches(/^[aA-zZ\s]*$/, t('companySharesModals:validation.company.chars'))
      .required(t('companySharesModals:validation.required')),
    sharesQuantity: Yup.number()
      .moreThan(0, t('companySharesModals:label.errorInfo.tooLittle'))
      .required(t('companySharesModals:validation.required')),
    shareholder: Yup.object().shape({
      shareholderId: Yup.string().required(t('companySharesModals:validation.required')),
    })
  })

  const formik = useFormik({
    initialValues: emptyInitialValues,
    validationSchema: ShareSeriePostValuesSchema,
    onSubmit: async (values: ShareSerieInputValues, { setSubmitting }: FormikHelpers<ShareSerieInputValues>) => {
      const { sharesSerieName, sharesQuantity, shareholder } = values;
      const erc20SharesSerieName = `${company?.companyName}, Serie ${sharesSerieName}, Shares ${sharesQuantity}`;
      const erc20SharesSerieSymbol = `${sharesSerieName}${sharesQuantity}`; // max 11 znaków
      const salt: BytesLike = utils.hexZeroPad(utils.formatBytes32String(`${erc20SharesSerieSymbol}-${v4()}`.slice(0, 31)), 32);
      const [chosenShareholder] = shareholders.filter((sh) => sh.shareholderId === shareholder.shareholderId);
      setGetAddressArgs(
        [
          chosenShareholder.chainAddress,
          erc20SharesSerieName,
          erc20SharesSerieSymbol,
          Number(sharesQuantity),
          salt
        ]
      );

      deployTx(chosenShareholder.chainAddress, erc20SharesSerieName, erc20SharesSerieSymbol, sharesQuantity, salt);

      setReadyForSubmit(false);
    }
  });

  useEffect(() => {
    if (deployState.status === "PendingSignature" && !readyForSubmit) {
      setIsLoading(true);
    }
    if (deployState.status === "Exception" && !readyForSubmit) {
      setIsLoading(false);
    }
    if (deployState.status === "Success" && !readyForSubmit) {
      const { sharesSerieName, sharesQuantity, shareholder } = formik.values;
      const postValues: ShareSeriePostValues = {
        sharesSerieName: sharesSerieName,
        sharesQuantity: sharesQuantity,
        shareholderId: shareholder?.shareholderId,
        companyId: company?.companyId,
        sharesSerieAddress: `${newSerieAddress}`
      }
      addShareSerie(postValues)
        .then((res) => {
          formik.setSubmitting(false);
          onSuccess();
        })
        .catch(err => {
          formik.setSubmitting(false);
          onError();
        })
      setIsLoading(false);
      setReadyForSubmit(true);
    }
  }, [company?.companyId, deployState, formik, newSerieAddress, onError, onSuccess, readyForSubmit])



  const renderContent = () => {
    return (
      <DialogContent>
        <Box component='form' >
          <Grid container spacing={4} >
            <Grid item xs={12}>
              <Grid container >
                <Typography variant="h5" sx={{ mb: 2 }}>{t('companySharesModals:words.sharesSerie')}</Typography>

                <Grid item xs={12}>
                  <TextField
                    label={t('companySharesModals:label.sharesSerieName')}
                    error={formik.errors.sharesSerieName ? true : false}
                    type="text"
                    name="sharesSerieName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sharesSerieName}
                    helperText={
                      formik.errors.sharesSerieName
                      && formik.touched.sharesSerieName
                      && formik.errors.sharesSerieName
                    }
                    variant="outlined"
                  />
                  <TextField
                    label={t('companySharesModals:label.sharesQuantity')}
                    error={formik.errors.sharesQuantity ? true : false}
                    type="text"
                    name="sharesQuantity"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sharesQuantity === 0 ? "" : formik.values.sharesQuantity}
                    helperText={
                      formik.errors.sharesQuantity
                      && formik.touched.sharesQuantity
                      && formik.errors.sharesQuantity
                    }
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ my: "30px" }}>
                <Typography variant="h5" sx={{ mb: 2 }}>{t('companySharesModals:words.shareholder')}</Typography>

                <Grid item xs={12}>
                  <Box>
                    <Select
                      name="shareholder.shareholderId"
                      value={formik.values.shareholder.shareholderId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {shareholders.map(shareholder => {

                        return (
                          <MenuItem key={shareholder.shareholderId} value={shareholder.shareholderId}>
                            {shareholder.firstName}&nbsp;
                            {shareholder.lastName}&nbsp;
                            {shareholder.pesel}
                          </MenuItem>
                        )
                      })}
                    </Select>
                    <FormHelperText error>
                      {
                        formik.errors.shareholder?.shareholderId &&
                        formik.touched.shareholder?.shareholderId &&
                        formik.errors.shareholder.shareholderId
                      }
                    </FormHelperText>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    )
  }

  return <ActionModal
    loading={isLoading}
    submitBtnLabel={t('companySharesModals:btn.addCompany')}
    header={t('companySharesModals:header.addSharesSerie')}
    content={renderContent()}
    onSubmit={formik.handleSubmit}
    onClose={onClose} />
};

export default AddShareSerieModal;