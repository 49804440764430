import { useEffect, useState } from "react";
import { JsonFragment } from "@ethersproject/abi";
import { utils, Contract, BytesLike } from "ethers";
import { TransactionStatus, useCall, useContractFunction, useEthers } from "@usedapp/core";
import companySerieFactory from "contracts/CompanySerieFactory/CompanySerieFactory.json";
import roleManagementABI from "contracts/RoleManagement/ABI.json";
import sharesSerieABI from "contracts/ShareSerie/ABI.json";

export type GetAddressArgs = [
  wallet: string,
  erc20SharesSerieName: string,
  erc20SharesSerieSymbol: string,
  sharesQuantity: number,
  salt: BytesLike
] | undefined

export const getContract = (
  contractAddress: string,
  abi: string | readonly (string | utils.Fragment | JsonFragment)[]
): Contract => {
  const contractInterface = new utils.Interface(abi);
  const contract = new Contract(contractAddress, contractInterface);

  return contract;
}

// Company Factory Contract
export const useDeployNewSerie = (contractAddress: string): {
  deployTx: (...args: unknown[]) => Promise<void>;
  deployState: TransactionStatus;
  deployEvents: utils.LogDescription[] | undefined;
} => {
  const contract = getContract(contractAddress, companySerieFactory.abi);
  const { state: deployState, send: deployTx, events: deployEvents } = useContractFunction(contract, 'deploy', { transactionName: "Deploy New Serie" });
  return { deployState, deployTx, deployEvents };
}

export const useGetAddress = (contractAddress: string, args: GetAddressArgs): undefined | string => {
  const { library, account } = useEthers();
  const { value, error } = useCall(
    account &&
    args && {
      contract: new Contract(contractAddress, companySerieFactory.abi, library),
      method: 'getAddress',
      args: args
    }) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

// SharesSerie Contract
export const useForcedTransfer = (contractAddress: string): {
  transferShares: (args: [owner: string, newOwner: string, amount: string]) => Promise<void>;
  transferSharesState: TransactionStatus;
  transferEvents: utils.LogDescription[] | undefined;
} => {
  const { library } = useEthers();
  const contract = getContract(contractAddress, sharesSerieABI);
  const { state: transferSharesState, send: transferShares, events: transferEvents } = useContractFunction(contract, 'forcedTransfer', { signer: library?.getSigner() });

  return { transferShares, transferSharesState, transferEvents };
}

// Role Management Contract
export const useGetAdmins = (): undefined | string[] => {
  const [currAddressArray, setCurrAddressArray] = useState<string[]>();
  const contract = new Contract(window.__APP_CONFIG__.roleManagementAddress, roleManagementABI);
  const { value, error } = useCall(
    {
      contract: contract,
      method: 'showManagers',
      args: []
    }) ?? {};

  useEffect(() => {
    const addressArray = value?.[0];
    if (addressArray === undefined) {
      return;
    }
    if (currAddressArray === undefined || addressArray.length !== currAddressArray?.length || !currAddressArray.every((a1) => addressArray.includes(a1))) {
      setCurrAddressArray(addressArray);
    }

  }, [currAddressArray, value]);

  if (error) {
    console.error(error);
    return undefined;
  }
  return currAddressArray;
}

export const useAddAdmin = (): {
  sendNewAdmin: (args: [newAdminAccount: string]) => Promise<void>;
  sendNewAdminStatus: TransactionStatus;
  sendNewAdminEvent: utils.LogDescription[] | undefined;
} => {
  const { library } = useEthers();
  const contract = getContract(window.__APP_CONFIG__.roleManagementAddress, roleManagementABI);
  const { state: sendNewAdminStatus, send: sendNewAdmin, events: sendNewAdminEvent } = useContractFunction(contract, 'addMember', { signer: library?.getSigner() });

  return { sendNewAdmin, sendNewAdminStatus, sendNewAdminEvent };
}