import { Typography, Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NoMatch: React.FC = () => {
  const { t } = useTranslation('noMatch')
  const navigate = useNavigate();

  return (
    <Box sx={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ maxWidth: "500px" }}>
        <Typography variant="h2" component="h1">{t('noMatch:noMatch')}</Typography>
        <Button variant="contained"
          onClick={() => { navigate(-1) }}
        > {t('noMatch:goHome')}</Button>
      </Box>
    </Box >
  )
}

export default NoMatch;