import { Snackbar, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SnackbarMessage } from "./useSnackbarMessage";

const autoHideDuration = 3000;

type Props = {
  message: SnackbarMessage | undefined;
  onClose: () => void;
}

const SnackbarComponent: React.FC<Props> = ({ onClose, message }) => {
  const { t } = useTranslation('dashboard')

  if (message !== undefined) {
    return (
      <Snackbar open autoHideDuration={autoHideDuration} onClose={onClose}>
        <Alert severity={message.kind} sx={{ width: '100%' }}>
          {t(`${message.text}`)}
        </Alert>
      </Snackbar>
    )
  }

  return null;
}

export default SnackbarComponent;