import { useCallback, useState } from "react";

export type Kind = 'error' | 'success';

export type SnackbarMessage = {
  kind: Kind;
  text: string;
}

type UseSnackbarMessage = {
  message: SnackbarMessage | undefined;
  setErrorMessage: (text: string) => void;
  clearMessage: () => void;
  setSuccessMessage: (text: string) => void;
}

export const useSnackbarMessage = (): UseSnackbarMessage => {
  const [message, setMessage] = useState<SnackbarMessage>();

  const setSuccessMessage = useCallback((text: string) => {
    setMessage({ kind: 'success', text })
  }, [])

  const setErrorMessage = useCallback((text: string) => {
    setMessage({ kind: 'error', text })
  }, [])

  const clearMessage = useCallback(() => {
    setMessage(undefined)
  }, [])

  return { message, setErrorMessage, setSuccessMessage, clearMessage };
}