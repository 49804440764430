import { Box, Button, Divider, Link, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getCompanies, getOneCompanyShares } from "services";
import { Company, CompanySerie, Share, Shareholder, SharesSerie } from "const/types";
import SnackbarComponent from "components/snackbar";
import { useSnackbarMessage } from "components/snackbar/useSnackbarMessage";
import { useTranslation } from "react-i18next";
import AddShareSerieModal from "pages/company-shares/modals/add-share-serie-modal";
import { getCompanySeries } from "./tools";
import Loader from "components/loader";
import Accordion, { AccordionT } from "./components/accordion";
import TransferSharesModal from "./modals/transfer-shares-modal";

type AccordionState = Omit<AccordionT, "handleExpand">
type OpenModal = 'addSerie' | 'transfer' | false
type SharesTransfer = {
  sharesSerieId: string,
  sharesSerieName: string,
  sharesSerieAddress: string
}
const accordionStateInit: AccordionState = {
  expand: false,
  company: {
    companyName: "",
    serieAmount: 0,
    deployer: "",
    companySeriesAddress: ""
  }
}

const CompanyShares: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [accordionValues, setAccordionValues] = useState<AccordionState>(accordionStateInit);
  const [openModal, setOpenModal] = useState<OpenModal>(false);
  const [companySeries, setCompanySeries] = useState<CompanySerie[] | null>(null);
  const [sharesTransfer, setSharesTransfer] = useState<SharesTransfer>({
    sharesSerieId: "",
    sharesSerieName: "",
    sharesSerieAddress: ""
  });
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [shareholders, setShareholders] = useState<Shareholder[] | undefined>(undefined);
  const { t } = useTranslation('companyShares, snackbar, companySharesModals');
  const { companyID } = useParams();
  const navigate = useNavigate();
  const { message, setErrorMessage, setSuccessMessage, clearMessage } = useSnackbarMessage();
  const handleTransferClick = (serieId: string, serieName: string, serieAddress: string) => {
    setOpenModal('transfer');
    setSharesTransfer({
      sharesSerieId: serieId,
      sharesSerieName: serieName,
      sharesSerieAddress: serieAddress
    })
  }

  const fetchData = useCallback(() => {
    const companyId = companyID ? companyID : "";

    getOneCompanyShares(companyId)
      .then(res => {
        const shares: Share[] = res.data.shares;
        const shareSeries: SharesSerie[] = res.data.sharesSeries;
        const shareholders: Shareholder[] = res.data.shareholders;
        setCompanySeries(getCompanySeries(shares, shareSeries, shareholders));
        setShareholders(shareholders);
      })
      .catch(err => console.log(err));
  }, [companyID]);

  useEffect(() => {
    fetchData();
  }, [companyID, fetchData, openModal]);

  useEffect(() => {
    getCompanies()
      .then(res => {
        res.data.companies.forEach(oneCompany => {
          if (oneCompany.companyId === companyID) {
            setCompany(oneCompany);
            setAccordionValues(prev => ({
              ...prev, company:
              {
                companyName: oneCompany.companyName,
                serieAmount: companySeries?.length || 0,
                deployer: oneCompany.deployer,
                companySeriesAddress: oneCompany.companySeriesAddress
              }
            }))
          }
        })
      })
      .catch(err => console.log(err));
  }, [companyID, companySeries?.length]);

  useEffect(() => {
    if (companySeries) {
      setIsLoading(false);
    }
  }, [companySeries]);

  if (isLoading) return (<Loader />);
  return (
    <Box sx={{ p: "30px", overflow: "scroll", scrollbarWidth: "none" }}>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: '30px' }}>
          <Button onClick={() => { navigate(-1) }}>{'<<'}{t('companyShares:btn.back')}</Button>
          <Button onClick={() => setOpenModal('addSerie')}>{t('companyShares:btn.addSerie')}</Button>
        </Box>
        <Accordion
          expand={accordionValues.expand}
          handleExpand={() => setAccordionValues(prev => ({ ...prev, expand: !prev.expand }))}
          company={accordionValues.company} />
        {
          companySeries?.length !== 0 && companySeries?.map(serie => {
            return (
              <React.Fragment key={serie.sharesSerieName}>
                <Box sx={{ my: "30px" }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>
                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <Typography variant="h4" >{t('companyShares:words.serie')} {serie.sharesSerieName}</Typography>
                        <Button variant="contained" onClick={() => handleTransferClick(serie.sharesSerieId, serie.sharesSerieName, serie.sharesSerieAddress)}>{t('companySharesModals:btn.transfer')}</Button>
                      </Box>
                      <Link href={`https://ropsten.etherscan.io/token/${serie.sharesSerieAddress}`}>
                        <Typography variant="caption">{serie.sharesSerieAddress}</Typography>
                      </Link>
                    </Box>
                    <Typography variant="h4">{serie.sharesQuantity}</Typography>
                  </Box>
                  {serie.activeSharesRanges[0] &&
                    <Box>
                      <Typography variant="h5" sx={{ my: "30px" }}>{t('companyShares:titles.sharesAvailable')}</Typography>
                      {
                        serie.activeSharesRanges.map(item => {
                          return (
                            <Box sx={{ display: "flex", justifyContent: "space-between" }} key={item.rangeEnd}>
                              <Typography variant="body1">{t('companyShares:words.shareNumbers')} {item.rangeStart}-{item.rangeEnd}</Typography>
                              <Typography variant="body1">{item.shareholderName}</Typography>

                            </Box>
                          )
                        })
                      }
                    </Box>
                  }
                  {serie.blockedSharesRanges[0] &&
                    <Box>
                      <Typography variant="h5" sx={{ my: "30px" }}>{t('companyShares:titles.sharesBlocked')}</Typography>
                      {
                        serie.blockedSharesRanges.map(item => {
                          return (
                            <Box sx={{ display: "flex", justifyContent: "space-between" }} key={item.rangeEnd}>
                              <Typography variant="body1">{t('companyShares:words.shareNumbers')} {item.rangeStart}-{item.rangeEnd}</Typography>
                              <Typography variant="body1">{item.shareholderName}</Typography>
                            </Box>
                          )
                        })
                      }
                    </Box>
                  }
                </Box>
                <Divider variant="middle" />
              </React.Fragment>
            )
          })
        }
      </Box>

      {company && openModal === 'addSerie' &&
        <AddShareSerieModal
          onSuccess={() => {
            fetchData();
            setSuccessMessage(t('snackbar:success'));
            setOpenModal(false);
          }}
          onError={() => {
            setErrorMessage(t('snackbar:error.server'));
          }}
          onClose={() => setOpenModal(false)}
          company={company}
          shareholders={shareholders ? shareholders : []}
        />}
      {openModal === 'transfer' &&
        <TransferSharesModal
          onClose={() => setOpenModal(false)}
          onSuccess={() => {
            fetchData();
            setSuccessMessage(t('snackbar:success'));
            setOpenModal(false);
          }}
          onError={() => setErrorMessage(t('snackbar:error.server'))}
          sharesTransfer={sharesTransfer}
        />}
      <SnackbarComponent message={message} onClose={clearMessage} />
    </Box>
  )
}

export default CompanyShares

