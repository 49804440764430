import { Routes, Route } from "react-router-dom";
import NoMatch from "pages/404";
import Login from "pages/login";
import Layout from "layout/layout";
import AuthRoute from "./auth-route";
import CompaniesBoard from "pages/companies-board";
import CompanyShares from "pages/company-shares";
import Home from "pages/home";
import AdminsBoard from "pages/admin-board";
import Web3Layout from "layout/web3-layout";

export const Router: React.FC = () => {

  return (
    <Routes>
      <Route path="*" element={<NoMatch />} />
      <Route path="login" element={<Login />} />
      <Route path="/" element={<Layout />}>
        <Route element={<AuthRoute />}>
          <Route index element={<Home />} />
          <Route element={<Web3Layout />}>
            <Route path="companies" element={<CompaniesBoard />} />
            <Route path="companies/:companyID" element={<CompanyShares />} />
            <Route path="admins" element={<AdminsBoard />} />
          </Route>
        </Route>

      </ Route>

    </Routes >
  )
}

