import { Typography } from "@mui/material";
import { useEthers } from "@usedapp/core";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";


const Web3Layout: React.FC = () => {
  const { t } = useTranslation('web3Layout');
  const { chainId, account } = useEthers();
  const isRopsten = chainId === 3;

  useEffect(() => {
    if (window.ethereum) {
      // window.location.reload is recommended by metamask
      // https://docs.metamask.io/guide/ethereum-provider.html#events
      const reload = () => { window.location.reload() }
      window.ethereum.on('chainChanged', reload);
      window.ethereum.on('accountsChanged', reload);

      return () => {
        window.ethereum.removeListener('chainChanged', reload);
        window.ethereum.removeListener('accountsChanged', reload);
      };
    }
  }, []);

  return (
    <>
      {
        !account ?
          <Typography variant='h3' sx={{ p: "30px" }}>{t('web3Layout:warnings.noAccount')}</Typography>
          : isRopsten ?
            <Outlet />
            :
            <Typography variant='h3' sx={{ p: "30px" }}>
              {t('web3Layout:warnings.notRopsten')} <br />
            </Typography>
      }
    </>
  )
}

export default Web3Layout;