import { ContractFactory, providers } from "ethers";
import CompanySerieFactory from "contracts/CompanySerieFactory/CompanySerieFactory.json";

export type NewContractData = {
  contractAddress: string,
  owner: string,
  creationError: boolean
}

export const newCompanySerieFactory = async (): Promise<NewContractData> => {
  const signer = (new providers.Web3Provider(window.ethereum)).getSigner();
  const factory = new ContractFactory(CompanySerieFactory.abi, CompanySerieFactory.bytecode, signer);
  const contract = await factory.deploy(window.__APP_CONFIG__.roleManagementAddress);

  const deployedContractReceipt = await contract.deployTransaction.wait();

  const error = deployedContractReceipt?.status;

  return {
    contractAddress: contract.address,
    owner: deployedContractReceipt.from,
    creationError: Boolean(error),
  };
};
