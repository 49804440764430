import { useState } from "react";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useAuth from "core/context/auth";
import LanguageSelector from "components/language-selector";
import Web3Connect from "components/web3-connect";
import { useEthers } from "@usedapp/core";

type NavbarProps = {
  isMenuButton: boolean
}

const Navbar: React.FC<NavbarProps> = ({ isMenuButton }) => {
  const { account, deactivate } = useEthers()
  const { t } = useTranslation('navbar');
  const { resetJWTToken } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickLogout = () => {
    resetJWTToken();
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "0 2rem", boxShadow: '0 0 20px 15px rgb(71, 122, 255)', backgroundColor: 'rgb(20, 30, 56)' }}>

      <Box sx={{ display: "flex", alignItems: 'end' }}>
        <Typography sx={{ fontWeight: 'bold', color: 'white' }} variant="h1" component="h1">BOS </Typography>
        <Typography sx={{ margin: '0 0 1rem .5rem', color: 'white' }} variant="h5" component="h5">7bulls Sp. z.o.o</Typography>
      </Box>

      <Box sx={{ display: "flex", gap: 2, alignItems: 'end', margin: '1rem 0' }}>
        {isMenuButton && <Web3Connect account={account} handleDeactivate={deactivate} />}
        <Box sx={{ margin: 'auto auto' }} >
          {isMenuButton && <Button
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(event) => setAnchorEl(event.currentTarget)}>
            kaereste.eth
          </Button>}
          <LanguageSelector />
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClickLogout}>{t('navbar:btn.logout')}</MenuItem>
        </Menu>
      </Box>

    </Box>

  )
}

export default Navbar;

