import { useCallback, useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import AppsIcon from '@mui/icons-material/Apps';
import { links } from "./links";
import { useTranslation } from "react-i18next";
import jwt_decode, { JwtPayload } from "jwt-decode";

interface PayloadExtention extends JwtPayload {
  firstName: string,
  lastName: string,
  email: string,
  permission: string
}
function getDecodedPayload(): undefined | PayloadExtention {
  let decoded: undefined | PayloadExtention = undefined;

  const token = localStorage.getItem('token') || "";

  if (token !== "") {
    decoded = jwt_decode<PayloadExtention>(token)
  }
  return decoded
}

const Menu: React.FC = () => {
  const [decodedPayload, setDecodedPayload] = useState<PayloadExtention>()
  const location = useLocation();
  const [isMenuShort, setIsMenuShort] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>(location.pathname);
  const { t } = useTranslation('menu')
  const handleTokenChange = useCallback(() => {
    setDecodedPayload(getDecodedPayload())
  }, [])

  useEffect(() => {
    handleTokenChange();
    window.addEventListener('storage', handleTokenChange);
    return () => {
      window.removeEventListener('storage', handleTokenChange);
    }
  }, [handleTokenChange])
  // need a change => backend => add permissions to token
  const linksShow = links.filter((link) => link.permission !== decodedPayload?.permission)

  return (
    <List disablePadding sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%', backgroundColor: 'rgb(20, 30, 56)' }}>
      <ListItem button sx={{ padding: "3rem 0", display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => setIsMenuShort(!isMenuShort)}>
        <ListItemIcon sx={{ textAlign: 'center', color: "white", padding: "2rem 2rem" }} >
          <AppsIcon />
        </ListItemIcon>
      </ListItem>
      {linksShow.map(link => {
        return (
          <ListItem
            button
            key={link.translateName}
            selected={selectedItem === link.path}
            onClick={() => setSelectedItem(link.path)}
            component={Link}
            to={link.path}
            sx={{
              ':hover': {
                backgroundColor: 'rgb(40, 98, 250)'
              }
            }}>
            {isMenuShort ? (
              <ListItemIcon sx={{ textAlign: 'center', color: "white", padding: "2rem" }} >
                {link.icon}
              </ListItemIcon>
            ) : (<>
              <ListItemIcon sx={{ textAlign: 'center', color: "white", padding: "2rem" }} >
                {link.icon}
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={t(`menu:${link.translateName}`)}
                sx={{ textAlign: 'center', color: "white", padding: "2rem 2rem 2rem 0" }}
              />
            </>
            )}
          </ListItem>
        )
      })}
    </List >
  )
}

export default Menu;


