import { Box } from "@mui/material"
import Menu from "components/menu-main"
import Navbar from "components/navbar"
import { Outlet } from "react-router-dom"

const Layout: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Menu />
      <Box sx={{ flexGrow: '1', display: "flex", flexDirection: "column" }} >
        <Navbar isMenuButton={true} />
        <Outlet />
      </Box>
    </Box >
  )
}

export default Layout