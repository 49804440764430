import { Box, Button, Typography } from "@mui/material";
import { shortenIfAddress, useEthers } from '@usedapp/core'
import WalletConnectProvider from '@walletconnect/web3-provider'
// import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Web3Modal from "./modal";


type Web3ConnectProp = {
  account: string | null | undefined,
  handleDeactivate: () => void
}

const Web3Connect: React.FC<Web3ConnectProp> = ({ account, handleDeactivate }) => {
  const { t } = useTranslation('web3Connect');
  const [open, setOpen] = useState<boolean>(false)
  const { activateBrowserWallet, activate } = useEthers()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onConnect = async () => {
    try {
      const provider = new WalletConnectProvider({
        rpc: window.__APP_CONFIG__.rpcUrl
      })
      await provider.enable();
      activate(provider);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConnectToWallet = (value: string) => {
    switch (value) {
      case "WalletConnect":
        onConnect();
        handleClose();
        break;
      case "Metamask":
        activateBrowserWallet();
        handleClose();
        break;
      default:
        break;
    }
  }

  return (
    <Box sx={{ margin: 'auto auto' }} >
      {!account &&
        <Button variant="outlined" onClick={handleClickOpen}>
          {t('web3Connect:btn.web3Login')}
        </Button>
      }
      {account &&
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Button onClick={handleDeactivate}>
            {t('web3Connect:btn.web3Logout')}
          </Button>
          <Typography variant="body1" sx={{ color: "#fff" }}>{shortenIfAddress(account)}</Typography>
        </Box>
      }

      <Web3Modal
        open={open}
        handleClose={handleClose}
        connectToWallet={handleConnectToWallet}
      />
    </Box>
  );
}
export default Web3Connect;
