import { Accordion as AccMui, AccordionSummary, Typography, AccordionDetails, TableContainer, Paper, Table, TableBody, TableRow, TableCell } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export type AccordionT = {
  expand: boolean,
  handleExpand: (event: React.SyntheticEvent, expanded: boolean) => void,
  company: {
    companyName: string,
    serieAmount: number,
    deployer: string,
    companySeriesAddress: string
  }
}

const Accordion: React.FC<AccordionT> = ({ expand, company, handleExpand }) => {

  return (
    <AccMui expanded={expand} onChange={handleExpand}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="bh-content"
        id="bh-header"
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }} variant='h4'>
          {company.companyName}
        </Typography>
        {!expand && <Typography sx={{ color: 'text.secondary', my: "auto" }} component="span" > {`${company.serieAmount} Share Series`} </Typography>}
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Share Series</TableCell>
                <TableCell align="left"> {company.serieAmount} </TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Deployed by</TableCell>
                <TableCell align="left"> {company.deployer} </TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Contract deployed at: </TableCell>
                <TableCell align="left"> {company.companySeriesAddress} </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </AccMui>
  )
}

export default Accordion;