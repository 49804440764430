import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import jwt_decode, { JwtPayload } from "jwt-decode";
import { useCallback, useEffect, useState } from "react";

interface PayloadExtention extends JwtPayload {
  firstName: string,
  lastName: string,
  email: string
}
function getDecodedPayload(): undefined | PayloadExtention {
  let decoded: undefined | PayloadExtention = undefined;

  const token = localStorage.getItem('token') || "";

  if (token !== "") {
    decoded = jwt_decode<PayloadExtention>(token)
  }
  return decoded
}

const Home: React.FC = () => {
  const [decodedPayload, setDecodedPayload] = useState<PayloadExtention>()
  const handleTokenChange = useCallback(() => {
    setDecodedPayload(getDecodedPayload())
  }, [])

  useEffect(() => {
    handleTokenChange();
    window.addEventListener('storage', handleTokenChange);
    return () => {
      window.removeEventListener('storage', handleTokenChange);
    }
  }, [handleTokenChange])

  return (
    <Box sx={{ p: "30px" }}>
      <Typography variant="h4">
        Hello {
          decodedPayload?.firstName && decodedPayload?.firstName
        }
      </Typography>
    </Box>
  )
}

export default Home