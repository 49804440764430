import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import navbarEn from 'components/navbar/translations/en.json';
import navbarPl from 'components/navbar/translations/pl.json';
import snackbarEn from 'components/snackbar/translations/en.json';
import snackbarPl from 'components/snackbar/translations/pl.json';
import menuEn from 'components/menu-main/translations/en.json';
import menuPl from 'components/menu-main/translations/pl.json';
import web3ConnectEn from 'components/web3-connect/translation/en.json';
import web3ConnectPl from 'components/web3-connect/translation/pl.json';
import actionModalEn from 'components/modals/translations/en.json';
import actionModalPl from 'components/modals/translations/pl.json';
import web3LayoutEn from 'layout/web3-layout/translation/en.json';
import web3LayoutPl from 'layout/web3-layout/translation/pl.json';
import loginPl from 'pages/login/translation/pl.json';
import loginEn from 'pages/login/translation/en.json';
import noMatchPl from "pages/404/translations/pl.json";
import noMatchEn from "pages/404/translations/en.json";
import companiesBoardEn from "pages/companies-board/translation/en.json";
import companiesBoardPl from "pages/companies-board/translation/pl.json";
import companiesBoardModalEn from "pages/companies-board/modals/translations/en.json";
import companiesBoardModalPl from "pages/companies-board/modals/translations/pl.json";
import companySharesEn from "pages/company-shares/translations/en.json";
import companySharesPl from "pages/company-shares/translations/pl.json";
import companySharesModalsEn from "pages/company-shares/modals/translations/en.json";
import companySharesModalsPl from "pages/company-shares/modals/translations/pl.json";
import adminBoardEn from "pages/admin-board/translation/en.json";
import adminBoardPl from "pages/admin-board/translation/pl.json";

const resources = {
  "en-EN": {
    navbar: navbarEn,
    snackbar: snackbarEn,
    menu: menuEn,
    web3Connect: web3ConnectEn,
    noMatch: noMatchEn,
    login: loginEn,
    companiesBoard: companiesBoardEn,
    companiesBoardModal: companiesBoardModalEn,
    modals: actionModalEn,
    companyShares: companySharesEn,
    companySharesModals: companySharesModalsEn,
    adminBoard: adminBoardEn,
    web3Layout: web3LayoutEn,
  },
  "pl-PL": {
    navbar: navbarPl,
    snackbar: snackbarPl,
    menu: menuPl,
    web3Connect: web3ConnectPl,
    login: loginPl,
    noMatch: noMatchPl,
    companiesBoard: companiesBoardPl,
    companiesBoardModal: companiesBoardModalPl,
    modals: actionModalPl,
    companyShares: companySharesPl,
    companySharesModals: companySharesModalsPl,
    adminBoard: adminBoardPl,
    web3Layout: web3LayoutPl
  }
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    interpolation: {
      escapeValue: false
    }
  })

export default i18next;