import { useState, createContext, useCallback } from "react";

interface Props {
  children: React.ReactNode;
}

export interface Context {
  tokenData: string;
  setJWTToken: (token: string) => void;
  resetJWTToken: () => void;
}


export const AuthContext = createContext<Context | undefined>(undefined);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [token, setToken] = useState<string>("");
  const setJWTToken = useCallback((token) => {
    localStorage.setItem('token', token);
    setToken(token)
  }, []);

  const resetJWTToken = useCallback(() => {
    localStorage.removeItem('token');
    setToken("")
  }, []);

  const values: Context = {
    tokenData: token, setJWTToken, resetJWTToken
  }

  return (
    <AuthContext.Provider value={values}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider