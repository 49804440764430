import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useAuth from 'core/context/auth'
import { useEffect } from 'react';
import jwt_decode, { JwtPayload } from "jwt-decode";

interface PayloadExtention extends JwtPayload {
  firstName: string,
  lastName: string,
  email: string,
  permission: string,
}

const AuthRoute: React.FC = () => {
  const { setJWTToken, resetJWTToken } = useAuth();
  const location = useLocation();
  const token = localStorage.getItem("token")
  const decoded = token ? jwt_decode<PayloadExtention>(token) : undefined;

  useEffect(() => {
    const now = Number(Date.now().toString().slice(0, 10));
    if (token) {
      if (decoded?.exp !== undefined && decoded?.exp > now) {
        setJWTToken(token);
      } else {
        resetJWTToken();
      }
    }
    else {
      resetJWTToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    token ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default AuthRoute