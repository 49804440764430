import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';

export const links = [
  {
    translateName: 'home',
    path: '/',
    icon: <HomeIcon />,
    permission: "user"
  },
  {
    translateName: "companies",
    path: "/companies",
    icon: <BusinessIcon />,
    permission: "user"
  },
  {
    translateName: 'contact',
    path: '/Contact',
    icon: <ContactSupportIcon />,
    permission: "user"
  },
  {
    translateName: 'admins',
    path: '/admins',
    icon: <ContactSupportIcon />,
    permission: "user"
  },
]
