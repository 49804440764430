import { Box, DialogContent, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import ActionModal from "components/modals/action";
import { addCompany } from "services";
import { newCompanySerieFactory } from "contracts/tools/ether-deploy";

type AddCompanyModalProps = {
  onSuccess: () => void;
  onError: (val: string) => void;
  onClose: () => void;
}
export type CompanyPostValues = {
  companyName: string,
  krs: string,
  companySeriesAddress: string,
  deployer: string
}
type InitialNewCompanyValues = {
  companyName: string,
  krs: string,
}
const emptyInitialValues: InitialNewCompanyValues = {
  companyName: "",
  krs: ""
}

const AddCompanyModal: React.FC<AddCompanyModalProps> = ({ onSuccess, onError, onClose }) => {
  const { t } = useTranslation('companiesBoardModal');

  const CompanyPostValuesSchema = Yup.object().shape({
    companyName: Yup.string()
      .matches(/^[aA-zZ\s]*$/, t('companiesBoardModal:validation.company.chars'))
      .required(t('companiesBoardModal:validation.required')),
    krs: Yup.string()
      .matches(/^[0-9]{10}$/, t('companiesBoardModal:validation.company.chars'))
      .required(t('companiesBoardModal:validation.required')),
  });

  const formik = useFormik({
    initialValues: emptyInitialValues,
    validationSchema: CompanyPostValuesSchema,
    onSubmit: async (values: InitialNewCompanyValues, { setSubmitting }: FormikHelpers<InitialNewCompanyValues>) => {
      const { companyName, krs } = values;
      const { contractAddress, owner, creationError } = await newCompanySerieFactory();

      if (creationError) onError('creationError');

      const postValues: CompanyPostValues = {
        companyName: companyName,
        krs: krs,
        companySeriesAddress: contractAddress,
        deployer: owner
      };

      addCompany(postValues)
        .then((res) => {
          onSuccess();
        })
        .catch(err => {
          onError('server');
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  });

  const renderContent = () => {
    return (
      <DialogContent>
        <Box component='form' >
          <Grid container spacing={4} >
            <Grid item xs={12}>
              <Grid container >
                <Typography variant="h5" sx={{ mb: 2 }}>{t('companiesBoardModal:words.company')}</Typography>

                <Grid item xs={12}>
                  <TextField
                    label={t('companiesBoardModal:label.copanyName')}
                    error={formik.errors.companyName ? true : false}
                    type="text"
                    name="companyName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyName}
                    helperText={
                      formik.errors.companyName
                      && formik.touched.companyName
                      && formik.errors.companyName
                    }
                    variant="outlined"
                  />
                  <TextField
                    label={t('companiesBoardModal:label.krs')}
                    error={formik.errors.krs ? true : false}
                    type="text"
                    name="krs"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.krs}
                    helperText={
                      formik.errors.krs
                      && formik.touched.krs
                      && formik.errors.krs
                    }
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    );
  };

  return <ActionModal
    loading={formik.isSubmitting}
    submitBtnLabel={t('companiesBoardModal:btn.addCompany')}
    header={t('companiesBoardModal:header.addCompany')}
    content={renderContent()}
    onSubmit={formik.handleSubmit}
    onClose={onClose} />
};

export default AddCompanyModal;