import { CssBaseline } from "@mui/material";
import { Config, DAppProvider, Ropsten } from "@usedapp/core";
import AuthProvider from "core/context/auth/provider";
import { Router } from "routing";

const config: Config = {
  readOnlyChainId: Ropsten.chainId,
  readOnlyUrls: {
    [Ropsten.chainId]: window.__APP_CONFIG__.rpcUrl
  },
}

const App: React.FC = () => {

  return (
    <>
      <CssBaseline />
      <DAppProvider config={config}>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </DAppProvider>
    </>
  );
}

export default App;
