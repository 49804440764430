import { AxiosResponse } from 'axios'
import { Shareholder } from 'const/types';
import { GetCompaniesResponse, ShareSeriePostValues, GetOneCompanySharesResponse, UpdateShareholdersShare, ShareholderWithShares } from 'const/types/services';
import { CompanyPostValues } from 'pages/companies-board/modals/add-modal';
import { FormValues } from 'pages/login';
import { axiosInstanceWithAuthorization, defaultAxiosInstance } from "./instances";

type MessageResponse = {
  message: string,
}

export const login = (user: FormValues): Promise<AxiosResponse<string>> => {
  return defaultAxiosInstance.post(`/users/login`, user);
}

export const getCompanies = (): Promise<AxiosResponse<GetCompaniesResponse>> => {
  return axiosInstanceWithAuthorization.get('/companies');
}

export const addCompany = (company: CompanyPostValues): Promise<AxiosResponse<MessageResponse>> => {
  return axiosInstanceWithAuthorization.post(`/companies`, company);
}
export const getShareholdersSharesSerie = (sharesSerieId: string): Promise<AxiosResponse<ShareholderWithShares[]>> => {
  return axiosInstanceWithAuthorization.get(`/shares/series/getShareholders`, { params: { sharesSerieId: sharesSerieId } });
}

export const addShareSerie = (shareSerieData: ShareSeriePostValues): Promise<AxiosResponse<MessageResponse>> => {
  return axiosInstanceWithAuthorization.post(`/shares/series`, shareSerieData);
}

export const getOneCompanyShares = (companyId: string): Promise<AxiosResponse<GetOneCompanySharesResponse>> => {
  return axiosInstanceWithAuthorization.get(`/shares/getOneCompanyShares`, { params: { companyId: companyId } });
}

export const getShareholders = (): Promise<AxiosResponse<Shareholder[]>> => {
  return axiosInstanceWithAuthorization.get('/shareholders');
}

export const updateShareholdersShare = (body: UpdateShareholdersShare): Promise<AxiosResponse<MessageResponse>> => {
  return axiosInstanceWithAuthorization.post('/shares/updateShareholders', body);
}