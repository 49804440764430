import { Box, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import PasswordIcon from '@mui/icons-material/Password';
import { login } from "services";
import useAuth from "core/context/auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "components/navbar";
import { Formik, FormikProps, FormikHelpers } from 'formik';
import { AccountCircle } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import * as Yup from 'yup';
import { useSnackbarMessage } from "components/snackbar/useSnackbarMessage";
import SnackbarComponent from "components/snackbar";

export type FormValues = {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const { message, setErrorMessage, clearMessage } = useSnackbarMessage();
  const { setJWTToken } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation('login')
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('login:validation.email.structure')).required(t('login:validation.required')),
    password: Yup.string()
      .min(6, t('login:validation.password.length'))
      .required('Required')
  })

  return (
    <Box sx={{
      height: "100vh", width: "100%", display: "flex",
      flexDirection: "column"
    }}>
      <Navbar isMenuButton={false} />
      <Box sx={{
        margin: "auto"
      }}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="h2" align="center">PPRA</Typography>
          <Typography variant="h5" align="center">{t('login:login')}</Typography>
          <Box sx={{ display: "flex" }}>
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={LoginSchema}
              onSubmit={(values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
                login(values)
                  .then(res => {
                    setJWTToken(res.data);
                    navigate('/');
                  })
                  .catch(err => {
                    setErrorMessage(t('snackbar:error.badEmailOrPsw'))
                  })
                  .finally(() => {
                    setSubmitting(false);
                  })
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }: FormikProps<FormValues>) => (
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{ display: "flex", flexDirection: "column" }}
                >

                  <Box sx={{ display: "flex", flexDirection: "column" }}>

                    <TextField
                      sx={{
                        marginTop: "1rem"
                      }}
                      error={errors.email ? true : false}
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      label={t("login:labels.email")}
                      helperText={
                        errors.email && touched.email && errors.email
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle color="primary" />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined" />
                    <TextField
                      sx={{
                        marginTop: "1rem"
                      }}
                      error={errors.password ? true : false}
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      label={t("login:labels.password")}
                      helperText={
                        errors.password && touched.password && errors.password
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PasswordIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined" />

                  </Box>

                  <LoadingButton
                    variant="contained"
                    sx={{
                      mt: "2rem",
                      mb: "1rem",
                      alignSelf: "center"
                    }}
                    loading={isSubmitting}
                    type='submit'>
                    {t('login:login')}
                  </LoadingButton>

                </Box>
              )}
            </Formik>

            <SnackbarComponent message={message} onClose={clearMessage} />

          </Box>
        </Paper>
      </Box>
    </Box >
  );

}

export default Login;