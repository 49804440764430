import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useGetAdmins } from 'contracts/tools';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEthers } from '@usedapp/core';
import { Contract } from 'ethers';
import roleManagementABI from "contracts/RoleManagement/ABI.json";

const AdminsBoard: React.FC = () => {
  const { t } = useTranslation('adminBoard');
  const [isLoading, setIsLoading] = useState(true);
  const [revokeBtnDisabled, setRevokeBtnDisabled] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [onlyOneAdmin, setOnlyOneAdmin] = useState(true);
  const { account, library } = useEthers();
  const adminsAccounts = useGetAdmins();

  const signer = library?.getSigner();
  const contract = new Contract(window.__APP_CONFIG__.roleManagementAddress, roleManagementABI, signer);

  const newAdminSchema = Yup.object().shape({
    adminAccount: Yup.string()
      .matches(/^0x[a-fA-F0-9]{40}$/g, t('adminBoard:validation.newAdmin.incorrect'))
  });

  const formik = useFormik({
    initialValues: { adminAccount: "" },
    validationSchema: newAdminSchema,
    onSubmit: async (values: { adminAccount: string }, { setErrors, setSubmitting }) => {

      if (adminsAccounts?.includes(values.adminAccount) || values.adminAccount === "") {
        setErrors({ adminAccount: t('adminBoard:validation.newAdmin.exists') });
        setIsLoading(false);
        setSubmitting(false);
        return;
      }

      setIsLoading(true);
      try {
        const res = await contract.addMember(values.adminAccount);
        await res.wait();
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err)
      }
    }
  });

  const revokeAdmin = async (adminAddress: string) => {
    setRevokeBtnDisabled(true);
    try {
      const res = await contract.revokeAdmin(adminAddress);
      await res.wait();
      setRevokeBtnDisabled(false);
    } catch (err) {
      setRevokeBtnDisabled(false);
      console.log(err);
    }
  }

  useEffect(() => {
    if (adminsAccounts === undefined) setIsLoading(true)
    if (adminsAccounts !== undefined) {
      adminsAccounts.length === 1 && setOnlyOneAdmin(true);
      adminsAccounts.length > 1 && setOnlyOneAdmin(false);
      setIsLoading(false);
      account &&
        adminsAccounts.includes(account) &&
        setIsAdmin(true);
    }
  }, [account, adminsAccounts]);

  return (
    <TableContainer component={Paper} sx={{ p: "30px" }} >
      <Table aria-label="simple table" sx={{ maxWidth: "1200px" }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Box component="form">
                <Typography variant='h3'>
                  {t('adminBoard:header')}
                </Typography>
                {isAdmin &&
                  <>
                    <TextField
                      label={t('adminBoard:newAdmin')}
                      error={formik.errors.adminAccount ? true : false}
                      type="text"
                      name="adminAccount"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.adminAccount}
                      helperText={
                        formik.errors.adminAccount
                        && formik.touched.adminAccount
                        && formik.errors.adminAccount
                      }
                      variant="outlined"
                    />
                    <LoadingButton loading={isLoading} onClick={() => formik.handleSubmit()} >
                      {t('adminBoard:btn.addAdmin')}
                    </LoadingButton>
                  </>
                }
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody >
          {adminsAccounts !== undefined &&
            adminsAccounts.map((account, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {`${index + 1}`}
                  </TableCell>
                  <TableCell align="right" sx={{ display: "flex" }}>
                    {isAdmin &&
                      !onlyOneAdmin &&
                      <LoadingButton variant='contained' sx={{ mx: 1 }} disabled={revokeBtnDisabled} onClick={() => { revokeAdmin(account) }}>
                        {t('adminBoard:btn.revokeAdmin')}
                      </LoadingButton>
                    }
                    <Typography sx={{ my: "auto" }}>
                      {account}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer >
  );
}

export default AdminsBoard;