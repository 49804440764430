import { DialogContent, FormHelperText, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as Yup from 'yup';
import ActionModal from "components/modals/action";
import { Shareholder } from "const/types";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getShareholders, getShareholdersSharesSerie, updateShareholdersShare } from "services";
import { ShareholderWithShares } from "const/types/services";
import { Contract } from "ethers";
import shareSerieABI from "contracts/ShareSerie/ABI.json";
import { useContractFunction, useEthers, useNotifications } from "@usedapp/core";

type TransferSharesModalProp = {
  sharesTransfer: {
    sharesSerieId: string,
    sharesSerieName: string,
    sharesSerieAddress: string,
  },
  onSuccess: () => void,
  onError: () => void,
  onClose: () => void,
};
type UpdateSharesOwnersFormik = {
  amount: number,
  owner: string,
  newOwner: string,
  sharesIds: string[]
};

const TransferSharesModal: React.FC<TransferSharesModalProp> = ({ sharesTransfer, onClose, onSuccess, onError }) => {
  const { t } = useTranslation('companySharesModals');
  const [shareholdersOfSerie, setShareholdersOfSerie] = useState<ShareholderWithShares[] | undefined>(undefined);
  const [shareholders, setShareholders] = useState<Shareholder[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { library } = useEthers()
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const { notifications } = useNotifications();

  const signer = library?.getSigner();
  const contractSerie = new Contract(sharesTransfer.sharesSerieAddress, shareSerieABI, signer);
  const { send, state, events } = useContractFunction(contractSerie, "forcedTransfer");


  const TransferSharesSchema = Yup.object().shape({
    owner: Yup.string().required(),
    newOwner: Yup.string().required(),
    amount: Yup.number().moreThan(0, "should be more than 0").required()
  });

  const emptyInitialValues: UpdateSharesOwnersFormik = {
    owner: "", newOwner: "", sharesIds: [], amount: 0
  }

  const formik = useFormik({
    initialValues: emptyInitialValues,
    validationSchema: TransferSharesSchema,
    onSubmit: async (values: UpdateSharesOwnersFormik, { setSubmitting }) => {
      setIsLoading(true);
      const { owner, newOwner, amount } = values;
      const shareOwnerArr = shareholdersOfSerie?.filter(sh => sh.shareholderId === owner);
      const newShareOwnerArr = shareholders?.filter(sh => sh.shareholderId === newOwner);

      const shareOwner = shareOwnerArr ? shareOwnerArr[0].chainAddress : "";
      const newShareOwner = newShareOwnerArr ? newShareOwnerArr[0].chainAddress : "";

      send(shareOwner, newShareOwner, `${amount}000000000000000000`);
      setReadyToSubmit(false);
    }
  });

  useEffect(() => {
    if (state.status === "PendingSignature" && !readyToSubmit) {
      setIsLoading(true);
    }
    if (state.status === "Exception" && !readyToSubmit) {
      console.error(state.errorMessage);
      setIsLoading(false);
    }
    if (state.status === "Success" && !readyToSubmit) {
      const { newOwner, amount } = formik.values;
      const checkSharesIds = (): string[] | undefined => {
        const isShares = shareholdersOfSerie?.filter(sh => sh.shareholderId === formik.values.owner)[0].shares.map(share => share.shareId);
        if (isShares) {
          return isShares.slice(0, amount);
        }
        return undefined;
      }
      const sharesIdsArr = checkSharesIds();
      updateShareholdersShare({ newOwner: newOwner, sharesIds: sharesIdsArr ? sharesIdsArr : [] })
        .then((res) => {
          formik.setSubmitting(false);
          onSuccess();
        })
        .catch(err => {
          formik.setSubmitting(false);
          onError();
        });
      setIsLoading(false);
      setReadyToSubmit(true);
    }
  }, [events, formik, notifications, onError, onSuccess, readyToSubmit, shareholdersOfSerie, state]);

  useEffect(() => {
    getShareholdersSharesSerie(sharesTransfer.sharesSerieId)
      .then(shareholdersRes => setShareholdersOfSerie(shareholdersRes.data))
      .catch(err => console.error(err));
    getShareholders()
      .then(shareholders => setShareholders(shareholders.data))
      .catch(err => console.error(err));
  }, [sharesTransfer]);

  const getHeader = () => { return `${t('companySharesModals:header.transferSharesSerie')} ${sharesTransfer.sharesSerieName}` };
  const renderContent = () => {
    return (
      <DialogContent>
        <Box component='form' >
          <Grid container >
            <Grid item >
              <Grid container >
                <Grid item >
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    {
                      t('companySharesModals:words.from')
                    }
                  </Typography>
                  <Grid item xs={12}>
                    <Select
                      name="owner"
                      value={formik.values.owner}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {shareholdersOfSerie &&
                        shareholdersOfSerie.map((shareholder, index) => {
                          return (
                            <MenuItem key={index} value={shareholder.shareholderId}>
                              {shareholder.firstName}&nbsp;
                              {shareholder.lastName}&nbsp;
                              {shareholder.pesel}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText error>
                      {
                        formik.errors.owner &&
                        formik.touched.owner &&
                        formik.errors.owner
                      }
                    </FormHelperText>

                    <Box>
                      <TextField
                        label="Amount"
                        error={formik.errors.amount ? true : false}
                        type="number"
                        name="amount"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.amount}
                        helperText={
                          formik.errors.amount
                          && formik.touched.amount
                          && formik.errors.amount
                        }
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 3 }}>
                  <Grid item >
                    <Typography variant="h5" sx={{ mb: 2 }}>
                      {
                        t('companySharesModals:words.to')
                      }
                    </Typography>
                    <Grid item xs={12}>
                      <Select
                        name="newOwner"
                        value={formik.values.newOwner}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        {shareholders &&
                          shareholders.map((shareholder, index) => {
                            return (
                              <MenuItem key={index} value={shareholder.shareholderId}>
                                {shareholder.firstName}&nbsp;
                                {shareholder.lastName}&nbsp;
                                {shareholder.pesel}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText error>
                        {
                          formik.errors.newOwner &&
                          formik.touched.newOwner &&
                          formik.errors.newOwner
                        }
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    );
  };

  return <ActionModal
    loading={isLoading}
    submitBtnLabel={t('companySharesModals:btn.transfer')}
    header={getHeader()}
    content={renderContent()}
    onSubmit={formik.handleSubmit}
    onClose={onClose} />
};

export default TransferSharesModal;