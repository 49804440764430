import { CompanySerie, Share, Shareholder, SharesRange, SharesSerie } from "const/types"

export const getCompanySeries = (shares: Share[], shareSeries: SharesSerie[], shareholders: Shareholder[]): CompanySerie[] => {

  const shareholderNames = shareholders.reduce<Record<string, string>>((acc, shareholder) => {
    acc[shareholder.shareholderId] = `${shareholder.firstName} ${shareholder.lastName}`
    return acc
  }, {})

  const series: CompanySerie[] = shareSeries.map(shareSerie => {
    const activeSharesArr = shares?.filter(s => s.status === 'active' && s.sharesSerieId === shareSerie.sharesSerieId)
    const inactiveSharesArr = shares?.filter(s => s.status === 'inactive' && s.sharesSerieId === shareSerie.sharesSerieId)

    const getSharesRanges = (shareArr: Share[]): SharesRange[] => {
      const sharesRanges: SharesRange[] = []
      if (shareArr.length === 0) {
        return []
      }
      shareArr = shareArr.sort((share1, share2) => share1.shareNumber - share2.shareNumber)


      let currShareholderId = shareArr[0].shareholderId

      let currRange: SharesRange = {
        rangeEnd: shareArr[0].shareNumber,
        rangeStart: shareArr[0].shareNumber,
        shareholderName: shareholderNames[currShareholderId]
      }

      sharesRanges.push(currRange)

      for (const share of shareArr.slice(1)) {
        if (share.shareholderId === currShareholderId) {
          currRange.rangeEnd = share.shareNumber
        } else {
          currShareholderId = share.shareholderId
          currRange = {
            rangeEnd: share.shareNumber,
            rangeStart: share.shareNumber,
            shareholderName: shareholderNames[currShareholderId]
          }

          sharesRanges.push(currRange)
        }
      }
      return sharesRanges
    }

    return {
      sharesSerieName: shareSerie.sharesSerieName,
      sharesSerieId: shareSerie.sharesSerieId,
      sharesQuantity: shareSerie.sharesQuantity,
      sharesSerieAddress: shareSerie.sharesSerieAddress,
      activeSharesRanges: getSharesRanges(activeSharesArr),
      blockedSharesRanges: getSharesRanges(inactiveSharesArr)
    }
  })

  return series
}