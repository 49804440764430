import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Company, ModalName } from "const/types";
import { useTranslation } from "react-i18next";
import { getCompanies } from "services";
import LinkList from "components/link-list";
import Loader from "components/loader";
import SnackbarComponent from "components/snackbar";
import { useSnackbarMessage } from "components/snackbar/useSnackbarMessage";
import AddCompanyModal from "./modals/add-modal";

const CompaniesBoard: React.FC = () => {
  const [companies, setCompanies] = useState<Company[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<ModalName>(undefined);
  const { message, setErrorMessage, setSuccessMessage, clearMessage } = useSnackbarMessage();
  const navigate = useNavigate();
  const { t } = useTranslation('companiesBoard, snackbar');


  const handleClick = (companyId: string) => {
    navigate(`/companies/${companyId}`);
  }

  const fetchData = useCallback(() => {
    getCompanies()
      .then(res => {
        setCompanies(res.data.companies);
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    if (companies) {
      setIsLoading(false);
    }
  }, [companies]);

  useEffect(() => {
    fetchData()
  }, [fetchData]);

  if (isLoading) return (<Loader />);
  return (
    <>
      <Box sx={{ p: "30px" }}>
        <Box sx={{ p: "30px", display: "flex", justifyContent: "space-between" }}>
          <Typography variant={"h3"} >{t('companiesBoard:words.companies')}</Typography>
          <Button
            variant="outlined"
            onClick={() => setOpenModal('add')}
          >{t('companiesBoard:btn.addCompany')}</Button>
        </Box>
        <Divider variant="middle" />
        {
          companies &&
          <LinkList companyList={companies} handleClick={handleClick} />
        }
      </Box>

      {openModal &&
        <AddCompanyModal
          onSuccess={() => {
            fetchData();
            setSuccessMessage(t('snackbar:success'))
            setOpenModal(undefined);
          }}
          onError={(errorType) => {
            setErrorMessage(t(`snackbar:error.${errorType}`))
          }}
          onClose={() => {
            setOpenModal(undefined)
          }}
        />
      }

      <SnackbarComponent message={message} onClose={clearMessage} />
    </>
  )
}

export default CompaniesBoard