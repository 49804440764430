import { Dialog, DialogTitle, Divider } from "@mui/material";

type Props = {
  header?: React.ReactNode;
  content: React.ReactNode;
  footer: React.ReactNode;
  open?: boolean;
}

const Modal: React.FC<Props> = ({ header, content, footer, open = true }) => {

  return (
    <Dialog open={open}>
      <DialogTitle>
        {header}
      </DialogTitle>
      {header && <Divider sx={{ backgroundColor: '#5A80FF', margin: "1rem 0" }} />}
      {content}
      {header && <Divider sx={{ backgroundColor: '#5A80FF', margin: "1rem 0" }} />}
      {footer}
    </Dialog>
  );
}

export default Modal;