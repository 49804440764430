import { Box, Button, Typography } from "@mui/material"
import { Company } from "const/types"


type LinkListProps = {
  companyList: Company[],
  handleClick: (linkItem: string) => void,
}
const LinkList: React.FC<LinkListProps> = ({ companyList, handleClick }) => {

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
      {companyList.map(company => {
        return (
          <Button
            key={company.companyName}
            onClick={() => handleClick(company.companyId)}
          >
            <Typography variant="button">{company.companyName}</Typography>
          </Button>
        )
      })}
    </Box>
  )
}

export default LinkList